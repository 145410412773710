'use client';
import { usePathname } from 'next/navigation';
import React from 'react';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { getLocalePathFromLanguageRegion } from 'utils/locale';

const LogoWrapper = ({
  children,
  region,
  language,
}: {
  children: JSX.Element;
  region: RegionCodesType;
  language: LanguageCodesType;
}) => {
  const pathName = usePathname();
  const iso = getLocalePathFromLanguageRegion(language, region);
  const id = `Nahdionline-${region}-home-page`;
  const isRootPath = pathName === '/' + iso;
  return isRootPath ? <h1 id={id}>{children}</h1> : children;
};

export default LogoWrapper;
